:root{
  --yellow : #FCD451;
  --navy : #514f00;
  --blackTransparent : rgba(0,0,0,.25);
  --body: #3d3d2c;
  --muted: #757c6c;
  --muteddark: #3c4234;
  --lightbg: #f4f4ea;
  --bright-color: #bfe700;
}

::selection {
  background: var(--bright-color) !important;
}

.btn-primary{
  background: var(--bright-color) !important;
  border-color: var(--bright-color) !important;
}

body{
  color: var(--body);
}

a{
  text-decoration: none;
}

a:hover{
  text-decoration: none;
}

.text-muted, .btn-outline-secondary {
  color: var(--muted) !important;
}

.btn-outline-secondary, .btn-outline-secondary:hover{
  border-color: var(--muted) !important;
}

.btn-outline-secondary:hover{
  background: var(--muted) !important;
  color:#fff !important;
}

.bg-light{
  background-color: var(--lightbg) !important;
}

.bg-secondary{
  background-color: var(--muted) !important;
}

.bg-secondaryd{
  background-color: var(--muteddark) !important;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background-color: var(--navy) !important;

}

.nav-link{
  color: var(--navy);
}

li{
  letter-spacing: 0;
}

.bggolden{
  background: linear-gradient(to right,#F9D451,#F7F4AE,#FCD451,#9E752B,#F9D451,#FCD451,#F9D451,#9E752B,#FCD451,#FCD451);
}

.text-blue, a, a.text-blue:not([href]){
  color: var(--navy);
}

.pointer{
  cursor: pointer;
}

.spaceforimage img{
  width: 100% ;
}

.bg-transaparent-black{
  background-color: var(--blackTransparent);;
}

.bottom{
  position: bottom;
}

.projects-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects-mosaic{
  border: 11px solid transparent;
  background-color: transparent;
  opacity: 0;
  display: flex;
  justify-content: end;
  height: 329px;
  transition: .5s all ease;
  flex-direction: column;
}

.projects-container:hover .projects-mosaic{
  border: 11px solid var(--yellow);
  background-color: var(--blackTransparent);
  opacity: 1;
}


.bgblue{
  background: var(--navy);
  background: -moz-radial-gradient(circle, rgba(5,26,91,1) 40%, rgba(7,18,51,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(5,26,91,1) 40%, rgba(7,18,51,1) 100%);
  background: radial-gradient(circle, rgba(5,26,91,1) 40%, rgba(7,18,51,1) 100%);
  background-color: rgba(0, 0, 0, 0);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#051a5b",endColorstr="#071233",GradientType=1);
}

.mainnavcell .dropdown-menu{
  width:90%;
  text-align:center;
  left: 50% !important;
  top:60px !important;
  transform: translate(-50%, 0) !important;
}

.mainnavcell .dropdown-menu.show a{
  color:#212529 !important;
}


header .mainnav a, .btnnavigation{
  padding: 3.7rem 0.6rem;
}

header .mainnav a.dropdown-item{
  padding: .6rem 0.6rem;
  font-size: .75rem;
}

header .dropdown-menu.show{
  top: 95px !important;
}

.font{
  font-family: 'Playfair Display', serif;
  letter-spacing: 0px;
  line-height: 160%;
}


.font2{
  font-family: 'Nunito', sans-serif;
  letter-spacing: 2px;
}

.mainnavcell a.nav-link{
  padding: 1.75rem 1rem;
  font-size: 170%;
}

.btn{
  text-transform: uppercase;
}

img{
  max-width: 100%;
}

.bgimg{
  background-repeat: no-repeat;
  background-position: center;
}

.bgimgc{
  background-image: contain;
}

.bgimgv{
  background-size:cover;
}

.bgimgh{
  background-size: cover;
}

.bgimghcustom{
  background-size: cover;
}

.bgimgbottomright{
  background-position: right bottom;
}

.square {
  width: 100%;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.rectangle {
  width: 100%;
}

.rectangle:after {
  content: "";
  display: block;
  padding-bottom: 70%;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: var(--navy);
  color:white;
}

.min-height-300{
  min-height: 300px;
}

.video{
  height: 85vh;
}

.thumb .carousel-indicators {
  margin-right: 0 !important;
  margin-left: 0 !important;;
}

.thumb .carousel-indicators li {
  width: 80px !important;
  height: 43px !important;
}

.itemthumb{
  opacity: .5;
}

.carousel-inner{
  height:100%;
}

.itemthumb.active{
  opacity: 1;
}

.itemthumb{
  height: 40px;
}

.text-white a{
  color:#fff;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 35px;
  height: 35px;
}

.bg-secondaryd{
  background-color:#343a40;
}

.reveal-vertical{
  position: relative;
  transform: translateY(100px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal-vertical.active{
  transform: translateY(0);
  opacity: 1;
}

.reveal-right{
  position: relative;
  transform: translateX(250px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal-right.active{
  transform: translateX(0);
  opacity: 1;
}

.reveal-left{
  position: relative;
  transform: translateX(-250px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal-left.active{
  transform: translateX(0);
  opacity: 1;
}

.reveal-zoom{
  position: relative;
  transform: scale(.5);
  opacity: 0;
  transition: 1s all ease;
}

.reveal-zoom.active{
  transform: scale(1);
  opacity: 1;
}


#loader {
  animation: animate 1.5s linear infinite;
  clip: rect(0, 80px, 80px, 40px);
  height: 80px;
  width: 80px;
  position: absolute;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
}

.financing .bgimgv {
  background-size: auto 100%;
}

@keyframes animate {
  0% {
      transform: rotate(0deg)
  }
  100% {
      transform: rotate(220deg)
  }
}

#loader:after {
  animation: animate2 1.5s ease-in-out infinite;
  clip: rect(0, 80px, 80px, 40px);
  content:'';
  border-radius: 50%;
  height: 80px;
  width: 80px;
  position: absolute;
}

@keyframes animate2 {
  0% {
      box-shadow: inset #fff 0 0 0 17px;
      transform: rotate(-140deg);
  }
  50% {
      box-shadow: inset #fff 0 0 0 2px;
  }
  100% {
      box-shadow: inset #fff 0 0 0 17px;
      transform: rotate(140deg);
  }
}

@media (max-width:1370px ){
  .video{
      height: 75vh;
  }
}

@media (max-width:1300px ){
  .video{
      height: 60vh ;
  }
}

@media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait){
  .video{
      height: 51vh !important;
  }
}

@media (max-width:1200px ){
  .video{
      height: 81vh ;
  }

  .video video{
      width: auto !important;
      height: 100% !important;
  }

  .carousel-control-prev, .carousel-control-next {
      width: 7%;
  }
  .whysbh .bgimgv{
      background-size: auto 100% !important;
      background-position: -790px bottom;
  }
}

@media (min-width: 500px){
  .financing .bgimgv{
      background-size: auto 100%;
      background-position: center -63px;
      background-size: 201% auto !important;
  }
}

@media (min-width: 1200px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 100%;
  }

  .modal-xl {
      max-width: 80% !important;
  }

  .homeslide .carousel-control-prev, .carousel-control-next{
      width:50px;
  }
}

@media (min-width: 1400px){
  .financing .bgimgv, .getstarted .bgimgv{
      background-size: cover !important;
  }

}

@media (min-width: 1600px){
  .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1500px;
  }

  .financing .bgimgv, .getstarted .bgimgv{
      background-size: cover !important;
  }
}


@media (min-width: 1800px) {
  .whysbh .bgimgv, .financing .bgimgv, .getstarted .bgimgv {
      background-size: cover !important;
  }

  .financing .bgimgv.bgimgbottomright{
      background-position: top;
      background-size: cover !important;
  }
}


@media (min-width: 1900px){
  .whysbh .bgimgv{
      background-size: cover !important;
  }

  .getstarted .bgimgv {
      background-size: auto 113% !important;
  }
}


@media (min-width: 2200px){
  .whysbh .bgimgv{
      background-size: 100% auto !important;
  }

  .getstarted .bgimgv {
      background-size: auto 128% !important;
  }

  .financing .bgimgv {
      background-size: 102% auto !important;
  }
}



@media (max-width: 1400px){
  .whysbh .bgimgv, .getstarted .bgimgv{
      background-size:auto 120% !important;
  }

  .financing .bgimgv{
      background-size: 191% auto !important;
  }
  .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 100%;
  }
}


@media (min-width: 1024px){
  .min-height-300.familyimg{
      min-height: 550px;
  }

  .financing .bgimgv{
      background-size: auto 100%;
  }
  
}
@media (min-width: 993px){
  .ipad{
      display: none;
  }
  .cell{
      display: none;
  }

  .pc{
      display: block;
  }

  
}



@media (max-width:993px){
.video{
      height: 55vh;
  }
}
  
@media (max-width:1366px){
  .cell{
      display: none;
  }
  .pc{
      display: none;
  }
  .ipad{
      display: block;
  }

  .bgimgwhy{
      min-height: 520px;
  }
  
}

@media (max-width: 835px){
  .ipad{
      display: none;
  }

  .cell{
      display: block;
  }

  .video {
      height: 43vh;
  }

  .header_container .container{
      padding:0 !important;
  }
  
  .header_container h3, .header_container .h3 {
      font-size: 1.5rem;
  }

  .whysbh .bgimgv{
      background-position: right bottom;
  }
  .header_container .rightmenu .btnnavigation{
      font-size: 18px;
  }
}

@media (max-width: 576px){
  .header_container .logospace.float-left{
      float:none !important;
      height:auto !important;
      width:100%;
      text-align:center;
  }
  
  .header_container .logospace a, .header_container .sloganwidth h3{
      display:block;
      width:100%;
  }
  
  .header_container .sloganwidth, .header_container .rightmenu{
      float:none !important;
      height:auto !important;
      width:100%;
      text-align:center;
  }

  .header_container .rightmenu .btnnavigation{
      width: 100%;
      height: auto;
      padding: 8px;
  }
  
  .bgimgwhy{
      display:none;
  }
  
  .video{
      height: 40vh;
  }

  #myVideo{
      height: 120% !important;
      width: auto !important;
  }
  
  .video.bgimgh {
      background-size: 191% auto;
  }
  
  .bgimghcustom {
      background-size: 210% auto;
  }

  .itemthumb{
      height: 70px;
  }

  .bgkitchen.bgimgh {
      background-size: 204% auto;
  }

}

@media (max-width: 400px){
  #mainvideo1{
      height: 100%;
  }

  .video.bgimgh {
      background-size: 250% auto;
  }

  h1, .h1 {
      font-size: 1.35rem;
  }

  h2, .h2 {
      font-size: 1.25rem;
  }

  .header_container h3, .header_container .h3 {
      font-size: 1rem;
  }

  h3, .h3 {
      font-size: 1rem;
  }
}